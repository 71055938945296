<app-basic-component [class.active]="showInstructions()">
  <ng-template #template>
    <div class="venue-cart__card" tabindex="0" (click)="cardClicked($event)" (keyup)="cardClicked($event)">
      @if (item().product; as product) {
        @defer {
          <div
            class="venue-cart__card__image non-selectable clickable"
            tabindex="0"
            (click)="imageClicked($event)"
            (keyup)="cardClicked($event)">
            @if (product.imageUrl && elementLoaded) {
              <img
                [@fadeAnimation]
                [ngSrc]="product.imageUrl"
                [alt]="'cart-item-photo' | translate"
                fill
                priority
                (error)="elementLoaded = false" />
            } @else {
              <nz-skeleton-element nzType="image" [nzActive]="true" [title]="'cart-item-photo' | translate"></nz-skeleton-element>
            }
            @if ((product?.price?.discountRate || 0) > 0) {
              <div [@fadeAnimation] class="discount">{{ product?.price?.discountRate }}&percnt;</div>
            }
          </div>
        }

        @defer {
          <span
            [@fadeAnimation]
            class="venue-cart__card__close clickable"
            [title]="'remove-cart-item' | translate"
            tabindex="0"
            (click)="cardRemoved($event)"
            (keyup)="cardRemoved($event)">
            <waytr-icon name="close" width="12px" height="12px"></waytr-icon>
          </span>
        }

        @defer {
          <div class="venue-cart__card__details" [@fadeAnimation]>
            <h3 class="venue-cart__card__details__title non-selectable">
              {{ product.localizedFields[currentLocale()].name | presentationcase }}
            </h3>
            <p class="venue-cart__card__details__price non-selectable">
              {{ product.price.finalPrice | currency: 'RON' : 'symbol-narrow' : '1.2' : 'ro' }} / {{ product.quantity.amount
              }}{{ product.quantity.scale }}
            </p>
            <div class="venue-cart__card__details__bottom">
              <div
                class="venue-cart__card__details__quantity non-selectable clickable"
                tabindex="0"
                (click)="changeQuantity($event, -1)"
                (keyup)="changeQuantity($event, -1)">
                <div class="venue-cart__card__details__quantity__less">
                  <span class="venue-cart__card__details__quantity__icon" [title]="'less-quantity' | translate">
                    <waytr-icon name="arrow_left" width="8px" height="8px"></waytr-icon>
                  </span>
                </div>
                <p class="venue-cart__card__details__quantity__number">
                  {{ item().quantity }}
                </p>
                <div
                  class="venue-cart__card__details__quantity__more clickable"
                  tabindex="0"
                  (click)="changeQuantity($event, 1)"
                  (keyup)="changeQuantity($event, 1)">
                  <span class="venue-cart__card__details__quantity__icon" [title]="'more-quantity' | translate">
                    <waytr-icon name="arrow_right" width="8px" height="8px"></waytr-icon>
                  </span>
                </div>
              </div>
              <p class="venue-cart__card__details__total non-selectable">
                {{ totalPrice | currency: 'RON' : 'symbol-narrow' : '1.2' : 'ro' }}
              </p>
            </div>
          </div>
        }
      }
    </div>
    <div class="accordion-wrapper" [class.expanded]="showInstructions()">
      <div>
        <textarea
          [class.active]="showInstructions()"
          [defaultValue]="item().instructions || ''"
          tabindex="0"
          (click)="stopPropagation($event)"
          (keyup)="stopPropagation($event)"
          (input)="changeInstructions($event)"
          rows="4"
          class="clickable"
          [placeholder]="'write-instructions-here' | translate"></textarea>
      </div>
    </div>
  </ng-template>
</app-basic-component>
