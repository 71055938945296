<button
  class="button-accent blur"
  [disabled]="disabled()"
  [class.animation]="animation()"
  tabindex="0"
  (click)="startAnimation()"
  [@removeAnimation]
  [@addAnimation]>
  <span class="title">{{ title() }}</span>
  <span class="icon" [class]="icon()"></span>
  <span class="details">{{ details() }}</span>
</button>
