import { effect, Inject, inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { IVenueAndTableParams } from '../interfaces';
import { JwtModel, JwtPayloadModel, NotificationModelResponseDto } from '../models';
import { LocalStorageService } from './local-storage.service';
import { VenueService } from './venue.service';
import { NOTIFICATIONS_WEBSOCKET_SERVICE, WebsocketService } from './websocket.service';

@Injectable({ providedIn: 'root' })
export class UserAccountService {
  readonly #venueService = inject(VenueService);
  readonly #localStorageService = inject(LocalStorageService);

  // Make sources private so it's not accessible from the outside
  readonly #userIsAdminSource: WritableSignal<boolean> = signal(false);
  readonly #userJWTSource: WritableSignal<JwtModel | undefined> = signal(undefined);
  readonly #userJWTDetailsSource: WritableSignal<JwtPayloadModel | undefined> = signal(undefined);

  // Exposed signals (read-only)
  readonly userIsAdmin: Signal<boolean> = this.#userIsAdminSource.asReadonly();
  readonly userJWT: Signal<JwtModel | undefined> = this.#userJWTSource.asReadonly();
  readonly userJWTDetails: Signal<JwtPayloadModel | undefined> = this.#userJWTDetailsSource.asReadonly();

  constructor(
    @Inject(NOTIFICATIONS_WEBSOCKET_SERVICE)
    private readonly notificationsWebsocketService: WebsocketService<NotificationModelResponseDto>,
  ) {
    effect(() => {
      this.sendNotificationsInitialParams();
    });
  }

  private sendNotificationsInitialParams() {
    const socketId = this.notificationsWebsocketService.socketId();
    const venueId = this.#venueService.venueId();
    const tableId = this.#venueService.tableId();
    const userDetails = this.userJWTDetails();

    if (venueId && tableId && userDetails) {
      this.removeOldSocketId(venueId, tableId);
      this.#localStorageService.saveData('socketId', socketId);
      this.notificationsWebsocketService.sendInitialParams({
        venueId: venueId,
        tableId: tableId,
        userId: userDetails.sub,
        roles: [userDetails.accountType],
      });
    }
  }

  private removeOldSocketId(venue: string, table: string) {
    // Client only code.
    const oldSocketId = this.#localStorageService.getData<string>('socketId');
    if (oldSocketId) {
      const params = {
        venueId: venue,
        tableId: table,
      } as IVenueAndTableParams;

      this.notificationsWebsocketService.sendMessage('CLIENT_DISCONNECTED', {
        ...params,
        socketId: oldSocketId,
      });
    }
  }

  setUserAccount(userJWTs: JwtModel) {
    this.#userJWTSource.set(userJWTs);

    try {
      const accessTokenJWT = jwtDecode<JwtPayloadModel>(userJWTs.accessToken);
      if (accessTokenJWT.iss === environment.API_URL) {
        this.#userJWTDetailsSource.set(accessTokenJWT);
      }
    } catch (Error) {
      console.debug("Couldn't decode user details");
    }
  }
}
