import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { addAnimation, removeAnimation } from 'src/app/animations';
import { stopPropagation } from 'src/app/helpers';
import { AppBasicComponent } from '../app-basic/app-basic.component';

@Component({
  selector: 'app-basic-notification',
  imports: [CommonModule, AppBasicComponent, TranslateModule],
  templateUrl: './app-basic-notification.component.html',
  styleUrls: ['./app-basic-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [addAnimation, removeAnimation],
})
export class AppBasicNotificationComponent {
  isClearable = input(true);
  hasAction = input(false);
  message = input('');
  icon = input('');
  protected notificationActionClickedEvent = output<string>();
  protected stopPropagation = stopPropagation;

  acceptClicked(event: MouseEvent | TouchEvent | KeyboardEvent) {
    stopPropagation(event);
    this.notificationActionClickedEvent.emit('accept');
  }

  declineClicked(event: MouseEvent | TouchEvent | KeyboardEvent) {
    stopPropagation(event);
    this.notificationActionClickedEvent.emit('decline');
  }
}
