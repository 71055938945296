import { Inject, Injectable, effect, inject, untracked } from '@angular/core';
import { CART_ITEMS_PATH } from '../constants';
import { IUserVenueAndTableParams } from '../interfaces';
import { CartItemModelRequestDto, CartItemModelResponseDto, SuborderModelRequestDto, SuborderStatusEnum } from '../models';
import { ApiService } from './api.service';
import { OrdersService } from './orders.service';
import { UserAccountService } from './user-account.service';
import { VenueService } from './venue.service';
import { CART_ITEMS_WEBSOCKET_SERVICE, WebsocketService } from './websocket.service';

@Injectable({ providedIn: 'root' })
export class CartService {
  readonly #apiService = inject(ApiService<CartItemModelRequestDto, CartItemModelResponseDto>);
  readonly #ordersService = inject(OrdersService);
  readonly #venueService = inject(VenueService);
  readonly #userAccountService = inject(UserAccountService);

  // Exposed signals (read-only)
  readonly venueSessionsMessages = this.cartItemsWebsocketService.initialMessages;
  readonly venueSessionsUpdates = this.cartItemsWebsocketService.updates;

  constructor(
    @Inject(CART_ITEMS_WEBSOCKET_SERVICE)
    private readonly cartItemsWebsocketService: WebsocketService<CartItemModelResponseDto>,
  ) {
    effect(() => {
      const venueId = this.#venueService.venueId();

      if (venueId) {
        const params = {
          userId: this.#userAccountService.userJWTDetails()?.sub,
          venueId: this.#venueService.venueId(),
          tableId: this.#venueService.tableId(),
        } as IUserVenueAndTableParams;

        untracked(() => {
          this.cartItemsWebsocketService.sendInitialParams(params);
        });
      }
    });
  }

  addCartItems(cart: CartItemModelRequestDto[]): Promise<CartItemModelResponseDto[]> {
    return this.#apiService.postMany(CART_ITEMS_PATH, cart);
  }

  changeQuantity(cartItemId: string, quantity: number): Promise<CartItemModelResponseDto> {
    const cartItem: Partial<CartItemModelRequestDto> = {
      quantity: quantity,
    };

    if (cartItem.quantity === 0) {
      return this.#apiService.delete(CART_ITEMS_PATH, cartItemId);
    }

    return this.#apiService.patch(`${CART_ITEMS_PATH}/${cartItemId}`, cartItem);
  }

  changeInstructions(cartItemId: string, instructions: string): Promise<CartItemModelResponseDto> {
    const cartItem: Partial<CartItemModelRequestDto> = {
      instructions,
    };

    return this.#apiService.patch(`${CART_ITEMS_PATH}/${cartItemId}`, cartItem);
  }

  removeCartItem(cartItemId: string): Promise<CartItemModelResponseDto> {
    return this.#apiService.delete(CART_ITEMS_PATH, cartItemId);
  }

  mapToSuborderRequestDto(cartItems: CartItemModelResponseDto[]): SuborderModelRequestDto {
    return {
      venue: cartItems[0].venue as unknown as string,
      table: cartItems[0].table as unknown as string,
      cartItems: cartItems.map(obj => obj._id),
      status: SuborderStatusEnum.NEW,
      order: this.#ordersService.sessionOrder()?._id as string,
    };
  }
}
