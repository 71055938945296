import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  #cryptoKey!: CryptoKey;
  #cryptoKeyReady: Promise<void>;
  readonly #ivLength = 12;
  readonly #isLocalStorageAvailable: boolean = false;

  constructor(@Inject(PLATFORM_ID) private readonly platformId: string) {
    if (isPlatformBrowser(platformId)) {
      this.#isLocalStorageAvailable = true;
    }
    // Initialize the key generation Promise
    this.#cryptoKeyReady = this.#initializeCryptoKey();
  }

  // Initialize the encryption key
  async #initializeCryptoKey() {
    const keyMaterial = new TextEncoder().encode(environment.LOCAL_STORAGE_KEY);
    this.#cryptoKey = await crypto.subtle.importKey('raw', keyMaterial, 'AES-GCM', true, ['encrypt', 'decrypt']);
  }

  // Utility: Ensure cryptoKey is ready
  async #ensureCryptoKeyReady() {
    await this.#cryptoKeyReady;
  }

  // Encrypt data
  async #encrypt(text: string): Promise<string> {
    await this.#ensureCryptoKeyReady();
    const iv = crypto.getRandomValues(new Uint8Array(this.#ivLength));
    const encryptedData = await crypto.subtle.encrypt({ name: 'AES-GCM', iv }, this.#cryptoKey, new TextEncoder().encode(text));
    return this.#combineAndEncode(iv, encryptedData);
  }

  // Decrypt data
  async #decrypt(ciphertext: string): Promise<string> {
    await this.#ensureCryptoKeyReady();
    const { iv, data } = this.#decodeAndSplit(ciphertext);
    const decryptedData = await crypto.subtle.decrypt({ name: 'AES-GCM', iv }, this.#cryptoKey, data);
    return new TextDecoder().decode(decryptedData);
  }

  // Combine IV and encrypted data into a Base64 string
  #combineAndEncode(iv: Uint8Array, encryptedData: ArrayBuffer): string {
    const combined = new Uint8Array(iv.length + encryptedData.byteLength);
    combined.set(iv);
    combined.set(new Uint8Array(encryptedData), iv.length);
    return btoa(String.fromCharCode(...combined));
  }

  // Decode Base64 and split into IV and data
  #decodeAndSplit(ciphertext: string): { iv: Uint8Array; data: Uint8Array } {
    const combined = Uint8Array.from(atob(ciphertext), char => char.charCodeAt(0));
    const iv = combined.slice(0, this.#ivLength);
    const data = combined.slice(this.#ivLength);
    return { iv, data };
  }

  // Public: Save encrypted data
  public async saveData(key: string, value: unknown) {
    if (!this.#isLocalStorageAvailable) {
      console.warn('Local storage is not available');
      return;
    }
    try {
      const encryptedValue = await this.#encrypt(JSON.stringify(value));
      localStorage.setItem(key, encryptedValue);
    } catch (err) {
      console.error('Failed to save data', err);
    }
  }

  // Public: Get decrypted data
  public async getData<T>(key: string): Promise<T | null> {
    if (!this.#isLocalStorageAvailable) {
      console.warn('Local storage is not available');
      return null;
    }
    try {
      const encryptedData = localStorage.getItem(key);
      if (!encryptedData) return null;
      const decryptedData = await this.#decrypt(encryptedData);
      return JSON.parse(decryptedData) as T;
    } catch (err) {
      console.error('Failed to retrieve data', err);
      return null;
    }
  }

  // Public: Save plain (unencrypted) data
  public savePlainData(key: string, value: unknown) {
    if (!this.#isLocalStorageAvailable) {
      console.warn('Local storage is not available');
      return;
    }
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (err) {
      console.error('Failed to save plain data', err);
    }
  }

  // Public: Get plain (unencrypted) data
  public getPlainData<T>(key: string): T | null {
    if (!this.#isLocalStorageAvailable) {
      console.warn('Local storage is not available');
      return null;
    }
    try {
      const data = localStorage.getItem(key);
      return data ? JSON.parse(data) : null;
    } catch (err) {
      console.error('Failed to retrieve plain data', err);
      return null;
    }
  }

  // Public: Remove data by key
  public removeData(key: string) {
    if (!this.#isLocalStorageAvailable) {
      console.warn('Local storage is not available');
      return;
    }
    try {
      localStorage.removeItem(key);
    } catch (err) {
      console.error('Failed to remove data', err);
    }
  }

  // Public: Clear all data
  public clearData() {
    if (!this.#isLocalStorageAvailable) {
      console.warn('Local storage is not available');
      return;
    }
    try {
      localStorage.clear();
    } catch (err) {
      console.error('Failed to clear local storage', err);
    }
  }
}
