import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Signal, computed, inject, output } from '@angular/core';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { fadeAnimation } from 'src/app/animations';
import { debounce } from 'src/app/helpers';
import { CategoryModelResponseDto, VenueModelResponseDto } from 'src/app/models';
import { VenueService } from 'src/app/services/venue.service';
import { WaytrIcon } from '../waytr-icon/waytr-icon.component';
/* eslint-disable @angular-eslint/component-selector */

@Component({
  selector: 'venue-categories',
  templateUrl: './venue-categories.component.html',
  styleUrls: ['./venue-categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeAnimation],
  imports: [CommonModule, ScrollingModule, NzSkeletonModule, WaytrIcon],
})
export class VenueCategoriesComponent {
  readonly #venueService = inject(VenueService);

  protected categorySelectedEvent = output<string>();

  protected toggle = false;
  protected selectedItem!: string;

  #venue: Signal<VenueModelResponseDto | undefined> = this.#venueService.venue;
  protected categories: Signal<CategoryModelResponseDto[]> = computed(() => this.getCategories(this.#venue()));

  private getCategories(venue: VenueModelResponseDto | undefined) {
    return venue?.categories.sort((a, b) => (a.position ?? 0) - (b.position ?? 0)) || [];
  }

  @debounce(100)
  selectCategory(categoryName: string) {
    if (this.selectedItem === categoryName || this.selectedItem === undefined) {
      this.toggle = !this.toggle;
    }
    if (categoryName !== this.selectedItem && this.toggle === false) {
      this.toggle = !this.toggle;
    }
    this.selectedItem = categoryName;
    const category = this.toggle ? categoryName : '';
    this.categorySelectedEvent.emit(category);
  }
}
