@if (menuItemModal(); as menuItemModal) {
  <app-modal #appModal [ngClass]="isModalVisible() ? 'visible' : 'hidden'">
    <ng-template #template>
      <div class="item__glass non-selectable">
        @if (elementLoaded && menuItemModal.imageUrl) {
          <img
            class="item__glass__image"
            [ngSrc]="menuItemModal.imageUrl"
            [alt]="'menu-item-photo' | translate"
            priority
            fill
            (error)="elementLoaded = false"
            [@fadeAnimation]="menuItemModal.imageUrl" />
        } @else {
          <nz-skeleton-element nzType="image" [nzActive]="true" [title]="'menu-item-photo' | translate"></nz-skeleton-element>
        }
        @if ((menuItemModal?.price?.discountRate || 0) > 0) {
          <div class="discount">{{ 'discount' | translate }}&nbsp;{{ menuItemModal?.price?.discountRate }}&percnt;</div>
        }
      </div>
      <span class="item__icon clickable" tabindex="0" (click)="closeModal()" (keyup)="closeModal()">
        <waytr-icon name="close" width="12px" height="12px"></waytr-icon>
      </span>
      @if (category(); as category) {
        <div class="item__details" [@fadeAnimation]="menuItemModal.imageUrl">
          <section class="item__details__section">
            <p class="item__details__section__category non-selectable">
              {{ category?.name | presentationcase }}
            </p>
            <h3 class="item__details__section__title modal__title">
              {{ menuItemModal.localizedFields[currentLocale()].name | presentationcase }}
            </h3>
            <p class="item__details__section__description">
              {{ menuItemModal.localizedFields[currentLocale()].description }}
            </p>
          </section>
          <section class="item__details__section">
            <h4 class="item__details__section__title">
              {{ 'ingredients' | translate }}
            </h4>
            <p class="item__details__section__description">
              @if (menuItemModalIngredients()?.length) {
                @for (ingredient of menuItemModalIngredients(); track ingredient._id; let isLast = $last) {
                  {{ !isLast ? (ingredient?.name | presentationcase) + ', ' : (ingredient?.name | presentationcase) }}
                }
              } @else {
                {{ 'not-specified' | translate }}
              }
            </p>
          </section>
          <section class="item__details__section">
            <h4 class="item__details__section__title">
              {{ 'allergens' | translate }}
            </h4>
            <p class="item__details__section__description">
              @if (menuItemModalAllergens()?.length) {
                @for (allergen of menuItemModalAllergens(); track allergen._id; let isLast = $last) {
                  {{ !isLast ? (allergen?.name | presentationcase) + ', ' : (allergen?.name | presentationcase) }}
                }
              } @else {
                {{ 'not-specified' | translate }}
              }
            </p>
          </section>
          @if (category.icon) {
            <span class="item__details__icon" [title]="category.icon | titlecase">
              <waytr-icon [name]="category.icon" width="14px" height="14px"></waytr-icon>
            </span>
          }
          <div class="item__details__bottom-buttons non-selectable">
            <div class="item__details__bottom-buttons__quantities no-scrollbar">
              @for (size of menuItemModalSizes(); track size._id) {
                <button
                  [class.button-accent]="selectedSizeId === size._id"
                  class="clickable"
                  tabindex="0"
                  (click)="selectSize($event, size)"
                  (keyup)="selectSize($event, size)">
                  {{ size?.displayName }}
                  @if (size.price) {
                    <span>&plus;{{ size.price | currency: 'RON' : 'symbol-narrow' : '1.2' : 'ro' }}</span>
                  }
                </button>
              }
            </div>
            <button
              class="item__details__bottom-buttons__add-to-suborder button-accent clickable"
              tabindex="0"
              (click)="changeQuantity($event, 1)"
              [class.animation]="animation()"
              [class.added-quantity]="cartItem()?.quantity">
              <span class="item__details__bottom-buttons__add-to-suborder__title">
                {{ 'add-to-cart' | translate }}&nbsp;&#8212;&nbsp;{{
                  menuItemModal.price.finalPrice | currency: 'RON' : 'symbol-narrow' : '1.2' : 'ro'
                }}
              </span>
            </button>
          </div>
        </div>
      }
    </ng-template>
  </app-modal>
}
